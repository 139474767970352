// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * Here are all the elements shared across all the Party Profile's sections
 */

import styled from "styled-components";

// container of fields
export const FieldsGroupContainer = styled.div`
  columns: 2 auto;
  overflow: hidden;
  @media (max-width: 1200px) {
    column-count: 1;
  }
`;

export const FieldGroupsCover = styled.div<{ isFull: boolean }>`
  display: ${({ isFull }) => (isFull ? "flex" : "block")};
  justify-content: space-between;
  width: ${({ isFull }) => (isFull ? "100%" : "50%")};
  flex-wrap: wrap;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const CustomLoadingSpinner = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: ${({ theme }) =>
    theme.colors.WHITE !== "#FFFFFF"
      ? "saturate(0.8) brightness(0.86) hue-rotate(180deg) invert(1)"
      : "none"};
`;

export const NoContent = styled.div`
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin-top: 15px;
  font-family: "Roboto Condensed";
  color: ${({ theme }) => theme.colors.GREY[500]};
`;

export const CustomCardContainer = styled.div``;

export const CustomDiv = styled.div<{ id: string }>`
  display: ${({ id }) => (id ? "block" : "none")};
  height: 50vh;
`;

export const CustomFieldsGroupContainer = styled.div``;

export const SingleRowContainer = styled.div`
  display: table;
  width: 100%;
  margin-bottom: 33px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const SingleRowInternalContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

// every field is represented by a row
export const SingleRow = styled.div`
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-size: 16px;
  font-family: Roboto Condensed;
`;

export const SingleRowAlt = styled.div`
  display: flex;
  align-items: center;
  gap: 100px;
  margin-bottom: 20px;
`;

export const SingleRowTwoColumns = styled.div`
  column-span: all;
  margin-bottom: 97px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-size: 16px;
  font-family: Roboto Condensed;
  &:last-child {
    margin-bottom: 0px;
  }
`;

// row's name
export const FieldName = styled.div`
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  text-transform: capitalize;
  font-size: 18px;
  padding-bottom: 25px;
  font-family: Roboto Condensed;
`;

export const FieldNameAlt = styled.div`
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  text-transform: capitalize;
  font-size: 16px;
  font-family: Roboto Condensed;
`;

// row's value
export const FieldValue = styled.div`
  padding: 0px 0px 0px 0px;
  color: ${({ theme }) => (theme.colors.WHITE !== "#FFFFFF" ? "rgb(209, 203, 199)" : "#808080")};
  font-size: 13px;
  font-family: Roboto Condensed;
`;

export const FieldValueAlt = styled.div`
  padding: 0px 0px 0px 0px;
  color: ${({ theme }) => theme.colors.GREY[500]};
  font-size: 13px;
  font-family: Roboto Condensed;
`;

// filterable table shared styles

export const WrapTable = styled.div`
  white-space: nowrap;
  display: block;
  overflow-x: auto;
`;
export const TableBasicStyles = styled.table`
  border-collapse: collapse;
  margin-bottom: 24px;
  width: 100%;
`;

export const TableHeaderRow = styled.tr`
  background: rgba(54, 64, 115, 0.1);
  border-top: 1px solid rgba(54, 64, 115, 0.5);
`;

export const TableHeaderCell = styled.th`
  padding: 18px;
  &:first-child {
    padding-left: 40px !important;
  }
  &:last-child {
    padding-right: 40px !important;
  }
`;

export const TableHeaderContent = styled.div`
  display: flex;
  align-items: center;
`;
export const TableHeaderTitle = styled.span`
  font-weight: 700;
  font-size: 13px;
  font-family: Roboto Condensed;
  line-height: 20px;
  color: #364073;
  letter-spacing: 0.2px;
`;

export const TableBodyRow = styled.tr`
  border-bottom: 1px solid rgba(54, 64, 115, 0.5);
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
  :hover {
    background-color: rgba(54, 64, 115, 0.1);
  }
`;

export const TableBodyCell = styled.td`
  padding: 20px;
  font-weight: 500;
  font-size: 13px;
  font-family: Roboto Condensed;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #333333;
  &:first-child {
    padding-left: 40px !important;
  }
  &:last-child {
    padding-right: 40px !important;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-top: 20px;
`;

export const PaginationControl = styled.div`
  cursor: pointer;
  margin-right: 12px;
`;

export const PaginationControlNext = styled.div`
  transform: rotateZ(180deg);
  cursor: pointer;
  margin-left: 12px;
`;

export const PaginationPage = styled.div<{ active: boolean }>`
  background-color: ${(props) => `${props.active ? "#364073" : "#FFFFFF"}`};
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => `${props.active ? "#FFFFFF" : "#000000"}`};
  width: 24px;
  height: 24px;
  border: ${(props) => (props.active ? "none" : "1px solid rgba(0, 0, 0, 0.3)")};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
// end

// main container
export const HistoryContainer = styled.div`
  column-count: 2;
  @media (max-width: 1200px) {
    column-count: 1;
  }
`;

export const CustomDataSelector = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid rgba(128, 128, 128, 0.5);
  cursor: pointer;

  & button {
    text-align: left;
    cursor: pointer;
  }
`;
