import { useLocation, Link } from "react-router-dom";
import useModal from "hooks/useModal";

import { FunctionComponent, useMemo } from "react";
import { CLIENT_MENU_ITEMS } from "constants/clientMenu";
import { DIDO_DEMO_MENU_ITEMS } from "constants/dido";
import { ROLE, PATH } from "constants/index";
import { ADMIN_MENU_ITEMS } from "../../constants/adminMenu";
import {
  Container,
  Content,
  MenuContainer,
  MenuItem,
  MenuItemIcon,
  MenuItemText,
  MenuLink,
  Header,
  HeaderTitle,
  Logo,
  StyledCloseIcon,
  t360LogoPurple,
  signoutIcon,
} from "../../styles/admin/MobileMenu.styled";
import SignOut from "../alert/SignOut";

const isActive = (path: string, link: string) => {
  return path.includes(link);
};

const MobileMenu: FunctionComponent<{ show: boolean; close: () => void }> = ({ show, close }) => {
  const { pathname } = useLocation();

  const menuItems = useMemo(
    () =>
      pathname.includes(ROLE.ADMIN)
        ? ADMIN_MENU_ITEMS
        : pathname.includes(ROLE.CLIENT)
        ? CLIENT_MENU_ITEMS
        : DIDO_DEMO_MENU_ITEMS,
    [pathname],
  );

  const signOutModal = useModal();

  const openSignOutModal = () => {
    close();
    signOutModal.open();
  };

  return (
    <Container show={show} data-testid="mobile-menu">
      <SignOut controller={signOutModal} />
      <Content>
        <Header>
          <Link to={PATH.INDEX} onClick={close}>
            <Logo iconURL={t360LogoPurple} />
            <HeaderTitle>T360</HeaderTitle>
          </Link>
          <div data-testid="close" onClick={close}>
            <StyledCloseIcon />
          </div>
        </Header>
        <MenuContainer>
          {menuItems.map((menu) => (
            <MenuLink to={menu.link} key={menu.text} onClick={close} data-testid="menu-link">
              <MenuItem isActive={isActive(pathname, menu.link)}>
                <MenuItemIcon iconURL={menu.icon} isActive={isActive(pathname, menu.link)} />
                <MenuItemText>{menu.text}</MenuItemText>
              </MenuItem>
            </MenuLink>
          ))}
        </MenuContainer>

        {/* signout */}
        <MenuItem onClick={openSignOutModal}>
          <MenuItemIcon iconURL={signoutIcon} path={PATH.SIGNOUT} />
          <MenuItemText>Sign Out</MenuItemText>
        </MenuItem>
      </Content>
    </Container>
  );
};

export default MobileMenu;
