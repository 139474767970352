import styled from "styled-components";

export const TableContentContainer = styled.div<{
  noShadow?: boolean;
  noMarginTop?: boolean;
}>`
  display: block;
  column-span: all;
  overflow-x: auto;
  margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
  margin-bottom: 60px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-radius: 8px;
  padding: 12px 0;
  padding-bottom: 37px;
  white-space: nowrap;
  &:last-child {
    margin-bottom: 0px;
  }
  &:first-child {
    margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
  }
`;

export const RespTable = styled.div`
  width: 100%;
  display: table;
  border-collapse: collapse;
  // word-break: break-word;
`;

export const RespTableHeader = styled.div`
  display: table-header-group;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  background-color: rgba(54, 64, 115, 0.1);
  word-break: break-word; // this is a temporary fixd for table width
`;
export const TableHeaderCell = styled.div`
  display: table-cell;
  padding: 18px;
  &:first-child {
    padding-left: 40px !important;
  }
  &:last-child {
    padding-right: 40px !important;
  }
`;

export const RespTableBody = styled.div`
  display: table-row-group;
  padding: 0 40px;
`;

export const RespTableRow = styled.div<{ hasBorder?: boolean }>`
  display: table-row;
  border-bottom: ${(props) => (props.hasBorder ? "1px solid rgba(74, 130, 166, 0.5)" : "none")};
  font-size: 16px;
`;

export const TableBodyCell = styled.div`
  display: table-cell;
  padding: 18px;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 16px;
  &:first-child {
    padding-left: 40px !important;
  }
  &:last-child {
    padding-right: 40px !important;
  }
  color: ${({ theme }) => (theme.colors.WHITE !== "#FFFFFF" ? "#fff" : theme.colors.DARK[400])};
  & a {
    color: inherit;
    &:visited {
      color: inherit;
    }
    &:hover {
      color: #364073;
      opacity: 0.9;
    }
  }
`;

export const HeaderCover = styled.div`
  display: flex;
  height: fit-content;
  justify-content: flex-end;
`;

export const Button = styled.button<{ isActive?: boolean }>`
  width: 100px;
  text-align: center;
  margin-left: 5px;
  height: fit-content;
  margin-right: 5px;
  padding: 10px 0px;
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.PRIMARY[900] : theme.colors.WHITE};
  color: ${({ theme, isActive }) => isActive && theme.colors.WHITE};
  border: 1px solid ${({ theme }) => theme.colors.GREYED[300]};
  box-shadow: 0px 1px 2px ${({ theme }) => theme.colors.GREYED[300]};
  border-radius: 8px;
  cursor: pointer;
`;
