import { useLocation, Link } from "react-router-dom";
import useModal from "hooks/useModal";
import SignOut from "../alert/SignOut";

import * as S from "../../styles/admin/Sidebar.styled";
import { CLIENT_MENU_ITEMS } from "../../constants/clientMenu";

const t360LogoPurple = `${process.env.PUBLIC_URL}/assets/t360-header-logo-purple.svg`;

const signoutIcon = `${process.env.PUBLIC_URL}/assets/signout-icon.svg`;

const isActive = (path: string, link: string) => {
  return path.includes(link);
};

function SideBar() {
  const { pathname } = useLocation();

  const signOutModal = useModal();

  const openSignOutModal = () => {
    signOutModal.open();
  };

  return (
    <S.Container>
      <SignOut controller={signOutModal} />
      <S.Content>
        <S.Header>
          <Link to="/">
            <S.Logo iconURL={t360LogoPurple} />
            <S.HeaderTitle>T360</S.HeaderTitle>
          </Link>
        </S.Header>
        <S.MenuContainer>
          {CLIENT_MENU_ITEMS.map((menu) => (
            <S.MenuLink to={menu.link} key={menu.text}>
              <S.MenuItem isActive={isActive(pathname, menu.link)}>
                <S.MenuItemIcon iconURL={menu.icon} isActive={isActive(pathname, menu.link)} />
                <S.MenuItemText>{menu.text}</S.MenuItemText>
              </S.MenuItem>
            </S.MenuLink>
          ))}
        </S.MenuContainer>

        {/* signout */}
        <S.MenuItem onClick={openSignOutModal}>
          <S.MenuItemIcon iconURL={signoutIcon} path="signout" />
          <S.MenuItemText>Sign Out</S.MenuItemText>
        </S.MenuItem>
      </S.Content>
    </S.Container>
  );
}

export default SideBar;
